/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
// import { dataArray } from './data';
import { Card, Col, Row } from 'react-bootstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';
import useHttpApiCall from '../../../../services/useHttpApiCall';
import moment from 'moment';

export const SlideShow=()=>{
const navigate = useNavigate();
    
      const [tasks, setTasks] = useState([]);
      // eslint-disable-next-line no-unused-vars
      const [canPlaySound, setCanPlaySound] = useState(false);
//       const [alarmPlaying, setAlarmPlaying] = useState(false); // Track if the alarm is playing
// const onlineAudioUrl = "https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3"; // Replace with your online audio URL
const [slideshowPayload, setSlideshowPayload] = useState(undefined);  
const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
const [currentSlide, setCurrentSlide] = useState(0);
// eslint-disable-next-line no-unused-vars
const [startSlideShow,setStartSlideShow]=useState(false)
const [isFullscreen, setIsFullscreen] = useState(false);
const [isElementVisible, setIsElementVisible] = useState(true);
const [lastTimeStamp, setLastTimeStamp] = useState(null);
const TIME_TO_TRIGGER= 30 * 1000 
const EXTRATIME_TO_TRIGGER=120000
let addPointApiTimer, loadingTimer;
const is_shopfloor_viewer=localStorage.getItem('is_shopfloor_viewer')=='true'?true:false
const [data] = useHttpApiCall(slideshowPayload)
  const { id } = useParams();
        // Create audio instance for alarm sound
  // const alarmSound = new Audio(onlineAudioUrl);


  const nextSlide = () => {
    // tasks?.tagged_slides?.length==1?setCurrentSlideIndex(0):()=>{
    //   let index=currentSlideIndex+1
    //   index>(tasks?.tagged_slides?.length-1)?setCurrentSlideIndex(0):setCurrentSlideIndex(index)
    // }
    setCurrentSlideIndex((prevIndex) =>
      (prevIndex+1)>tasks?.tagged_slides?.length-1?0:prevIndex+1
    //  <((tasks?.tagged_slides?.length)-1) ? (prevIndex + 1) : 0
    );
    
  };
useEffect(()=>{
  currentSlideIndex>tasks?.tagged_slides?.length-1&& setCurrentSlideIndex(0)
  console.log('current index...',currentSlideIndex)
},[currentSlideIndex])
  useEffect(() => {
    const onFullscreenChange = () => {
      setIsFullscreen(!!document.fullscreenElement); // Check if fullscreen is active
    };

    // onKeyDown listens for Escape key press to hide the button or take actions
    const onKeyDown = (e) => {
      if (e.key === "Escape") {
        if (isElementVisible) {
          // Logic when the button is visible and Escape is pressed
          setIsElementVisible(false); // Hide the button or perform an action
        } else {
          setIsElementVisible(true); // Show the button when Escape is pressed again
        }
      }
    };

    // Listen to fullscreen change and Escape key press
    document.addEventListener("fullscreenchange", onFullscreenChange);
    document.addEventListener("keydown", onKeyDown);

    // Cleanup listeners when component unmounts
    return () => {
      document.removeEventListener("fullscreenchange", onFullscreenChange);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [isElementVisible]);  // Only rerun the effect when fullscreen state changes

  // const prevSlide = () => {
  //   setCurrentSlideIndex(
  //     (prevIndex) => (prevIndex > 0 ? prevIndex - 1 : tasks.tagged_slides.length - 1)
  //   );
    
  // };

  // Automatically move to the next slide every second
  useEffect(() => {
    const interval = setInterval(nextSlide, 6000); // 1 second
    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []);


 useEffect(() => {
    if (lastTimeStamp) {
      let lastTime = moment(lastTimeStamp);
      let diffrenceTime = moment().diff(lastTime); // Time left from current time to last updated
      console.log('difference time ', diffrenceTime, 'last time stamp', lastTimeStamp);

      if (diffrenceTime > TIME_TO_TRIGGER) {
        //console.log('difference time is more', diffrenceTime);
        diffrenceTime = TIME_TO_TRIGGER;
      }
      addPointApi(TIME_TO_TRIGGER, diffrenceTime);
    }
    return () => {
      clearTimeout(addPointApiTimer);
    };
  }, [lastTimeStamp, data])
  const addPointApi = (triggertime, diffrenceTime) => {
    clearTimeout(loadingTimer);
    clearTimeout(addPointApiTimer);
    let timeOutTime = triggertime - diffrenceTime + EXTRATIME_TO_TRIGGER;
    addPointApiTimer = setTimeout(function () {
      // setCheckOfflineCut(true);
      graphAddPointApiCall();
    }, timeOutTime);
  };

useEffect(()=>{
  setCanPlaySound(true)
},[])


useEffect(()=>{
  setSlideshowPayload(
    {
      auth: true,
      url: `v0/shop-floor-view/slide-shows/${id}/show/`
    }
  )
},[])
const graphAddPointApiCall=()=>{
  setSlideshowPayload({
    auth: true,
    url: `v0/shop-floor-view/slide-shows/${id}/show/`,
    params: {
      last_reading_time: lastTimeStamp,
    }
  });
}
useEffect(()=>{
  // const currentTime = new Date().getTime();
  setCurrentSlide(data?.data?.data?.tagged_slides?.[0])
  // data?.data?.data?.filter((item)=>item?.tagged_slides?.length>0)
  const filteredData = {
    ...data?.data?.data,
    tagged_slides: data?.data?.data.tagged_slides.filter(slide => slide.tagged_equipment.length > 0)
  };
  setTasks(filteredData)
setLastTimeStamp(data?.data?.last_reading_time)

},[data])
 useEffect(()=>{
 },[isElementVisible,startSlideShow,isFullscreen])
 useEffect(()=>{
  setCurrentSlide(tasks?.tagged_slides?.[currentSlideIndex])
 },[currentSlideIndex])
    // const calculateTimeDifference = (timestrap) => {
    //   const taskTime = new Date(timestrap).getTime();
    //   const currentTime = new Date().getTime();
    //   const timeDiff = currentTime - taskTime;
    //   const oneMinute = 20*60 * 60 * 1000; // 1 minute in milliseconds
    //   return timeDiff <= oneMinute; // Returns true if time difference is less than 1 minute
    // };
    // eslint-disable-next-line no-unused-vars
    function formatTime(timestamp) {
        const date = new Date(timestamp);
        const options = { hour: '2-digit', minute: '2-digit', second: '2-digit' };
        return timestamp==null?'': date.toLocaleTimeString([], options); // Format to only show time
      }

      const handleFullscreen = () => {
        const el = document.getElementById('element');
        if (el.requestFullscreen) {
          el.requestFullscreen();
        } else if (el.mozRequestFullScreen) { // Firefox
          el.mozRequestFullScreen();
        } else if (el.webkitRequestFullscreen) { // Chrome, Safari and Opera
          el.webkitRequestFullscreen();
        } else if (el.msRequestFullscreen) { // IE/Edge
          el.msRequestFullscreen();
        }
        setIsElementVisible(true);
        setIsFullscreen(true);
      };
      const handleExitFullscreen = () => {
    
    
          if (document.exitFullscreen) {
            document.exitFullscreen();
          } else if (document.mozCancelFullScreen) { // Firefox
            document.mozCancelFullScreen();
          } else if (document.webkitExitFullscreen) { // Chrome, Safari, and Opera
            document.webkitExitFullscreen();
          } else if (document.msExitFullscreen) { // IE/Edge
            document.msExitFullscreen();
          }
      
          setIsElementVisible(false); // Hide the element when exiting fullscreen
          setIsFullscreen(false);
        
    
      };
    
    return(
<div className='slideShowContainer'>
{/* <div className="container"> */}
  {/* <p><button onClick="var el = document.getElementById('element'); el.requestFullscreen();">
    Go Full Screen!
  </button></p>

</div> */}
 <div className="d-flex justify-content-between">
 <h6 className="inner-breadcrumb">
        <Link to={'/profile'}>
          {' '}
          <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>Back
        </Link>
      </h6>
      <span className='d-flex inner-breadcrumb' style={{'alignItems':'center',cursor:'pointer'}}  onClick={handleFullscreen}>
      Preview
      <i className=" material-icons notranslate graphoptionicons " onClick={handleFullscreen}>play_arrow</i> 

      </span>
 </div>

      {/* <div className='row'>
      <div className='col-2'></div>
        <div className='col-8'>
        <h4 className="text-center layout-heading">{tasks?.name}</h4>

        </div>
        <div className='col-2'>
        <div className="me-3">
      
          </div>
        </div>
      </div> */}
  {
    <div className='element p-3 '  id="element"    style={{
      display: isElementVisible ? 'block' : 'block', 
       backgroundColor: isElementVisible ? 'white' : 'white',
       transition: 'background-color 0.3s',
     }}>
      <div className='d-flex justify-content-end my-2'>
     
        {isFullscreen&&<button type="button" className="btn-close" aria-label="Close" onClick={()=>{
          setStartSlideShow(false),
          setIsElementVisible(false)
          handleExitFullscreen()
        }}></button>

        }
      
      </div>
    <div style={{ textAlign: 'center' }}>
        <h1 className='mb-5'>{tasks?.name}</h1>
    
        <Row >
        {tasks?.tagged_slides?.map((task, index) => 
       index==currentSlideIndex&& task?.tagged_equipment?.length>0? task?.tagged_equipment?.map(item=>
            (
              <Col key={index} className="mb-4" sm={12} md={6} lg={3} >
              <Card className={`${item.is_schedule_criteria_met?'flashing-border card':'default_card'}`} style={{ boxShadow: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }}>
                <Card.Body style={{height:'200px'}}>
                  <div className="d-flex justify-content-between align-items-center">
                    <Card.Title>
                      <div style={{height:'40px'}}>
                      {item?.equipment_name}
                      </div>
                    </Card.Title>
                    {
                    <i className=" blink_me material-icons notranslate graphoptionicons delete"  style={{opacity:`${item?.is_schedule_criteria_met?1:0}`}} onClick={()=>{setStartSlideShow(true)}}>warning</i> 
                  }
                  </div>
                  <Card.Text className='shop-floor-value'>
                    {item.kw_value||0} kW
                  </Card.Text>
                  <Card.Text className='shop-floor-description'>
                   {task.description}
                  </Card.Text>
               
      

                  <Card.Text>
                  <div className='d-flex justify-content-between'>
     {/* <span>machine_status</span> */}
     <span>{item?.machine_status}</span>
     <span>{formatTime(item?.timestamp_utc)}</span>
                    {/* <small style={{ color: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(item.machine_status)}</small> */}
                    </div>
                    {/* <div className='text-end'>
                    <small style={{ color: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }} className="text-muted">{formatTime(item.machine_status)}</small>
     
                    </div> */}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            )
           
           ):(
            <div key={index}> {  index==currentSlideIndex&&'No Data'}</div>
           )
        //   return(
         
        // )
        )}
      </Row> 
  
        {/* Control Buttons */}
       
      </div>
  
    </div>
  }
 







   
      {/* =========================card list======================== */}
      {/* <Row  >
      {tasks?.tagged_slides?.map((task, index) => 
     
         task?.tagged_equipment?.length>0&& task?.tagged_equipment?.map(item=>
          (
            <Col key={index} className="mb-4  mx-2" sm={12} md={4} lg={3} >
            <Card className={`${item?.is_schedule_criteria_met&&'flashing-border card'}`} style={{ boxShadow: `0px 4px 8px 0px `, transition: 'box-shadow 0.3s ease' }}>
              <Card.Body>
                <div className="d-flex justify-content-between align-items-center">
                  <Card.Title>
                    <div>
                    {item?.equipment_name}
                    </div>
                  </Card.Title>
                
                </div>
                <Card.Text className='shop-floor-value'>
                  {item.kw_value}kW
                </Card.Text>
                <Card.Text className='shop-floor-description'>
                 {task.description}
                </Card.Text>
                <Card.Text>
                <div className='d-flex justify-content-between'>
   <span>{item?.machine_status}</span>

                  </div>
               
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          )
         
         )
 
      )}
    </Row>  */}
    {/* ------------------------------------------------------------- */}
</div>

    )
}