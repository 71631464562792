/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Modal, Button, Form, Dropdown, DropdownButton, Container, Row, Alert } from 'react-bootstrap';
import {  Col, OverlayTrigger, Tooltip } from 'react-bootstrap';
import useHttpApiCall from '../../../../services/useHttpApiCall';
import MachineStatusCustomizeContainer from '../../../machinestatus/containers/machine.status.customize.container';
import { setCustomiseButtonClick } from '../../../../redux/customiseValues/filterbutton/customiseButton';
import { useDispatch, useSelector } from 'react-redux';
const MyModal = ({ show, handleClose ,formDataProps,isEdit,setHideModal}) => {
  // const [weekdays, setWeekdays] = useState({
  //   sunday: false,
  //   monday: false,
  //   tuesday: false,
  //   wednesday: false,
  //   thursday: false,
  //   friday: false,
  //   saturday: false,
  // });
  const [selectedWeekdays,setSelectedWeekdays]=useState([])
  const [selectedWeekdaysId,setSelectedWeekdaysId]=useState({})
  const [showToast, setShowToast] = useState(false);
  const daysOfWeek = [ 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday','sunday'];

        const [selectedItems, setSelectedItems] = useState([]);
        const [schedulePayload, setSchedulePayload] = useState([]);
        const [equipmentListPayload, setEquipmentListPayload] = useState('');
        const [equipmentList, setEquipmentList] = useState([]);

        const [selectedForm,setSelectedForm]=useState(1)
         const [openMachineStatusSlider, setOpenMachineStatusSlider] = useState(false);
               const [filterGraphId, setFilterGraphId] = useState(null);
               const [customiseModalVisibility,setCustomiseModalVisibility]=useState(false)
               const [enableNextButton,setEnableNextButton]=useState(false)

               const [customizeUpdate, setCustomizeUpdate] = useState({ filterGraphId: null, update: false });
               const customise_button_click = useSelector((state) => state.customiseButtonClick.customise_button_click);
        // const [data] = useHttpApiCall(payloadSlideShows)
        // eslint-disable-next-line no-unused-vars
        const [data2, loading2, error2] = useHttpApiCall(schedulePayload)
        const [data] = useHttpApiCall(equipmentListPayload)
        const dispatch = useDispatch();
        const flexible_equipment=localStorage.getItem('flexible_equipment')=='true'?true:false
        const plantCode=localStorage.getItem('plantCode')
        
  const [scheduleParams,setScheduleParams]=useState() 
useEffect(()=>{
  if (selectedWeekdays?.length>0&&scheduleParams?.name){
setEnableNextButton(true)
  }
  else{
    setEnableNextButton(false)
  }
},[selectedWeekdays,scheduleParams])
useEffect(()=>{
  !isEdit&&show&&setScheduleParams(
    { name:'',  machine_status:'OFF', schedule_time: [
  
      {
          schedule_day: 1,
          schedule_from_time: "09:00:00",
          schedule_to_time: "10:00:00"
      }
  ]}
  )
},[show])
useEffect(()=>{
  setScheduleParams(isEdit?formDataProps:{ name:'',  machine_status:'OFF', schedule_time: [
  
    {
        schedule_day: 1,
        schedule_from_time: "09:00:00",
        schedule_to_time: "10:00:00"
    }
]})

},[])
const handleCloseModal=()=>{
  setSelectedForm(1)
  setHideModal()
  setShowToast(false)
  setSelectedWeekdays([])
  setScheduleParams(formDataProps?.name?formDataProps:{   machine_status:'OFF', schedule_time: [
    {
        schedule_day: 1,
        schedule_from_time: "09:00:00",
        schedule_to_time: "10:00:00"
    }
]})
  handleClose()
}
useEffect(()=>{
  setEquipmentList(data?.data?.data)
},[data])
const scheduleDaysParams=()=>{
  let demo = ''; // Initialize an empty string

selectedWeekdays?.forEach((val, index) => {
  if (val && index < 3) {
    demo += (index + 1) + ','; // Append the value followed by a comma
  }
});

return(demo.slice(0, -1))
}
  // Handle button click for weekdays
  const handleWeekdayClick = (day,index) => {
    setSelectedWeekdays((prevIndexes) => {
      if (prevIndexes.includes(index)) {
        // Remove the index if it's already in the array
        return prevIndexes.filter((item) => item !== index);
      } else {
        // Add the index if it's not in the array
        return [...prevIndexes, index];
      }
    });
    // setSelectedWeekdays((prev)=>[...prev,index])
    // setWeekdays(prev => ({ ...prev, [day]: !prev[day] }));
    setEnableNextButton(selectedWeekdays.length>0?true:false)
  };
  const handleLoadEquipmentList=()=>{
    flexible_equipment?setEquipmentListPayload({
      auth: true,
      url: `v0/equipments/`,
      // method:'PATCH',
      params:{
        plant_code:plantCode,
        layer_type:'company',
        equipment_type:1,
        is_shop_floor_view:1,
        schedule_days:scheduleDaysParams(),
        // machine_status_condition:scheduleParams?.machine_status_condition?'SHOULD_BE':'SHOULD_NOT_BE',
        schedule_from:scheduleParams?.schedule_time[0]?.schedule_from_time,
        schedule_to:scheduleParams?.schedule_time[0]?.schedule_to_time

      }
  
    }):setEquipmentListPayload({
      auth: true,
      url: 'v0/equipments/',
      // method:'POST',
      params:{
        equipment_type:1,
        is_shop_floor_view:1,
        schedule_days:scheduleDaysParams(),
        // machine_status_condition:scheduleParams?.machine_status_condition?'SHOULD_BE':'SHOULD_NOT_BE',
        schedule_from:scheduleParams?.schedule_time[0]?.schedule_from_time,
        schedule_to:scheduleParams?.schedule_time[0]?.schedule_to_time

      }
  
  
    })
  }
  useEffect(()=>{
    formDataProps?.name&&setScheduleParams(formDataProps)
    // setSelectedWeekdays(formDataProps?.equipment_id?.length>0?formDataProps?.schedule_time?.map((item)=>{return item?.equipment_id }):[])
    setSelectedWeekdays(formDataProps?.schedule_time?.length>0?formDataProps?.schedule_time?.map((item)=>{return item?.schedule_day }):[])
    setSelectedItems(formDataProps?.schedule_equipment?.length>0?formDataProps?.schedule_equipment?.map((item)=>{return item?.equipment_id }):[])
    setScheduleParams((prevState) => ({
      ...prevState,
      'machine_status_condition': formDataProps?.machine_status_condition=="SHOULD_BE"
    }))
    setSelectedWeekdaysId( formDataProps?.schedule_time?.reduce((days, { schedule_day, id }) => {
      days[schedule_day] = id;
      return days;
  }, {}))


  },[formDataProps])
  useEffect(()=>{
    if(data2&&data2?.data){
      if(data2&&data2){
      
        if(data2?.data?.status==1){
          // setShowToast(false)
          handleCloseModal()
        }else if(data2[1]==true){

      setShowToast(true)
        }
    }

}
  },[data2,error2,loading2])
  const handleSubmit=()=>{
    const scheduleTime=selectedWeekdays?.map((item,index)=>{
      return selectedWeekdaysId?.[item]? {
        id:selectedWeekdaysId[item],
        schedule_day: item,
        schedule_from_time: scheduleParams?.schedule_time[0]?.schedule_from_time,
        schedule_to_time: scheduleParams?.schedule_time[0].schedule_to_time
      }:{
        schedule_day: item,
        schedule_from_time: scheduleParams?.schedule_time[0]?.schedule_from_time,
        schedule_to_time: scheduleParams?.schedule_time[0].schedule_to_time
      }
    })
    isEdit?setSchedulePayload({
        auth: true,
        url: `v0/shop-floor-view/schedules/${scheduleParams?.id}/`,
        method:'PATCH',
        data:{
          name:scheduleParams?.name,
          is_active:scheduleParams?.is_active,
          machine_status:scheduleParams?.machine_status,
          machine_status_condition:scheduleParams?.machine_status_condition?'SHOULD_BE':'SHOULD_NOT_BE',
          tagged_equipment:selectedItems,
          schedule_time:scheduleTime

        }
    
      }):setSchedulePayload({
        auth: true,
        url: 'v0/shop-floor-view/schedules/',
        method:'POST',
        data:{
          name:scheduleParams?.name,
          is_active:scheduleParams?.is_active,
          machine_status:scheduleParams?.machine_status,
          machine_status_condition:scheduleParams?.machine_status_condition?'SHOULD_BE':'SHOULD_NOT_BE',
          tagged_equipment:selectedItems,
          schedule_time:scheduleTime

        }
    
      })
      // handleClose()
    }
  // Handle time change
  const handleTimeChange = (e, timeType) => {
    const { value } = e.target;
    setScheduleParams((prevState) => {
      const updatedScheduleTime = [...prevState.schedule_time];
      updatedScheduleTime[0] = {
        ...updatedScheduleTime[0],
        [timeType]:`${value}:00`
      };
      return {
        ...prevState,
        schedule_time: updatedScheduleTime
      };
    });
  };

  // Handle status selection
  // const handleStatusChange = (newStatus) => {
  //   setStatus(newStatus);
  // };
  const handleChange = (e) => {
    const { name,value } = e.target;
    setScheduleParams((prevState) => ({
      ...prevState,
      [name]: value
    }));
  };
  const handleDropdownChange = (e) => {
    // eslint-disable-next-line no-unsafe-optional-chaining
    // const { name,value } = e?.target;
    setScheduleParams((prevState) => ({
      ...prevState,
      machine_status: e
    }));
  };
  const truncateText = (text, maxLength) => {
    // If the text length exceeds the max length, truncate and add "..."
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };
  const handleItemClick = (e,item) => {
    e.target.checked?setSelectedItems((prev)=>[...prev,item?.id]):setSelectedItems(selectedItems?.filter(prevItem=>prevItem!==item?.id))

  };
  const handleAddMachineStatusClick=(item)=>{
    setOpenMachineStatusSlider(true)
    setCustomiseModalVisibility(true)
    dispatch(setCustomiseButtonClick(item))
  }
  return (
    <div className='scheduleForm'>
 <Modal className='scheduleForm' show={show} onHide={()=>{handleCloseModal()}}   // Prevent closing by clicking outside
  backdrop="static"      // Prevent closing by clicking outside
  keyboard={false}>
                {openMachineStatusSlider && (
              <MachineStatusCustomizeContainer
                openMachineStatusSlider={openMachineStatusSlider}
                processDetails={{
                  drilldownlevel: 'Process',
                  process_type: 'Production',
                  process_id: customise_button_click?.process,
                  sub_process_id: customise_button_click?.sub_process_id
                }}
                setOpenMachineStatusSlider={setOpenMachineStatusSlider}
                setCustomizeUpdate={setCustomizeUpdate}
                filterGraphId={filterGraphId}
                handleClose={()=>{handleLoadEquipmentList()}}
              />
            )}
      <Modal.Header closeButton>
        <Modal.Title>Schedule Settings</Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <Alert
              className="sidebar-slider_alert"
              show={showToast}
              variant="danger"
              onClose={() => setShowToast(false)}>
              <p className="text-center">
              Error while creating schedule
              {/* {editMode ? 'Alert updated successfully' : 'Alert created successfully'} */}
              </p>
              </Alert>
        {
          selectedForm==1?<div>
  <div className='modalLabel mb-2'>
        Name
    
        </div>
         <input name='name' className='shadow rounded pl-1' style={{minHeight:'35px',marginBottom:'10px',paddingLeft:'10px'}} onChange={handleChange} value={scheduleParams?.name}></input>
      
        <Form.Group controlId="formWeekdays">
          <h5>Select Weekdays</h5>
          <div className="d-flex flex-wrap">
            {daysOfWeek.map((day,index) => (
              <Button
                key={day}
                variant={selectedWeekdays?.includes(index+1) ? 'success' : 'secondary'}
                className="m-1"
                onClick={() => handleWeekdayClick(day,index+1)}
              >
                {day.charAt(0).toUpperCase() + day.slice(1,3)}
              </Button>
            ))}
          </div>
        </Form.Group>

        {/* Time range */}
        <Form.Group controlId="formTime">
          <Form.Label className='d-block'>Enter Time</Form.Label>
          <Form.Control
            type="time"
            className='mr-5'
            timeFormat="HH:MM:SS"
            value={scheduleParams?.schedule_time?.length>0?scheduleParams?.schedule_time[0]?.schedule_from_time:'00:00'}
            onChange={(e) => handleTimeChange(e, 'schedule_from_time')}
          />
                  <Form.Control
            type="time"
                 timeFormat="HH:MM:SS"
            value={scheduleParams?.schedule_time?.length>0?scheduleParams?.schedule_time[0].schedule_to_time:'00:00'}
            onChange={(e) => handleTimeChange(e, 'schedule_to_time')}
          />
        </Form.Group>

        {/* Status dropdown */}
        <Form.Group controlId="formStatus">
          <h5>Status</h5>
          <DropdownButton
            variant="secondary"
            name='machine_status'
            id="dropdown-basic-button"
            title={scheduleParams?.machine_status?.charAt(0)?.toUpperCase() + scheduleParams?.machine_status?.slice(1)}
            onSelect={handleDropdownChange}
            value={scheduleParams?.machine_status}
            defaultValue={'OFF'}
          >
            <Dropdown.Item eventKey="PRODUCTION">PRODUCTION</Dropdown.Item>
            <Dropdown.Item eventKey="OFF">OFF</Dropdown.Item>
            <Dropdown.Item eventKey="SLEEP">SLEEP</Dropdown.Item>
            <Dropdown.Item eventKey="STANDBY">STANDBY</Dropdown.Item>
          </DropdownButton>
        </Form.Group>
        <Form.Group controlId="formStatus">
       <span>
       <h5>Should be</h5>
           <div>
                                    {/* <label className="mt-4 mb-2  modal-label">Machine Status condetion</label> */}
                                    <div className="text-left px-3">
                                        <label className="daz-switch mt-1">
                                            <input
                                                type="checkbox"
                                                onChange={() => { handleChange({ target: { name: 'machine_status_condition',
                                                   value: !scheduleParams.machine_status_condition } }) }}
checked={scheduleParams?.machine_status_condition }
                                                defaultValue={false}

                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    </div>

       </span>
       <span>
       <h5>Should not be</h5>
           <div>
                                    {/* <label className="mt-4 mb-2  modal-label">Machine Status condetion</label> */}
                                    <div className="text-left px-3">
                                        <label className="daz-switch mt-1">
                                            <input
                                                type="checkbox"
                                                onChange={() => { handleChange({ target: { name: 'machine_status_condition',
                                                   value: scheduleParams.machine_status_condition } }) }}
checked={!scheduleParams?.machine_status_condition }
                                                defaultValue={false}

                                            />
                                            <span className="slider round"></span>
                                        </label>
                                    </div>
                                    </div>

       </span>
        </Form.Group>
          </div>:<div>
          <h4  className='my-3'>{'Equipment List'}</h4>
        <div className='optionList'>
          <Container>
          {
            equipmentList?.length>0&&equipmentList?.map((item)=>(
              <div  key={item?.id} className='py-3 listItem' >
                
      <Row  className={`formListItem my-1 ${(item?.is_schedule_overlapped||item?.is_machine_status_level_set==false)?'formListItemdefaultBorder':'formListItemRedBorder'}` } >
      <OverlayTrigger
          placement="bottom"  // Position the tooltip at the bottom of the Col
          overlay={<Tooltip id="tooltip-bottom">{ item?.equipment_name}</Tooltip>}
        >
                      <Col xs={4} title={ item?.equipment_name}> { truncateText( item?.equipment_name,10)}</Col>

        </OverlayTrigger>
                      <Col xs={6}>{
                        !item?.is_machine_status_level_set&&    <div >
                        No values found in machine status.<span style={{color:'#1b75d0'}} onClick={()=>{handleAddMachineStatusClick(item)}}>Click here</span> to add limits for off,sleep-mode/stand by
                      show 
                      </div>
                      }
                      {
                        item?.is_schedule_overlapped&&    <div >
                        There is already an active schedule for this equipment during this time
                        {/* <span style={{color:'#1b75d0'}} onClick={()=>{handleAddMachineStatusClick(item)}}>Click here</span> to add limits for off,sleep-mode/stand by
                      show  */}
                      </div>
                      }
                  
                      </Col>
                      <Col className="d-flex justify-content-center align-items-center">
                         <input className="form-check-input " checked={selectedItems?.includes(item?.id)} type="checkbox"  onChange={(e)=>{handleItemClick(e,item)}} disabled={(item?.is_schedule_overlapped|| !item?.is_machine_status_level_set)}></input>
                      </Col>

                  </Row>
              </div>
            
              
               
            )
          
            )
          }
          </Container>
     

        </div>
          </div>
        }
 
    
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={()=>{selectedForm==1?handleCloseModal():setSelectedForm(1)}}>
          {selectedForm==1?'Close':'Back'}
        </Button>
        <Button variant="primary" className='btn-grad btn btn-primary' 
        disabled={!enableNextButton}
        onClick={() => { 
          // Handle form submission logic here
          selectedForm==1&&handleLoadEquipmentList()
          selectedForm==1?setSelectedForm(2):handleSubmit();
        }}>
          {
            selectedForm==1?'Next':' Save Changes'
          }
         
        </Button>
      </Modal.Footer>
    </Modal>
    </div>
   
  );
};

export default MyModal;