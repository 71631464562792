/* eslint-disable no-unused-vars */
import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import MainLayoutComponent from '../../../common/layout/components/main.layout.component';
import { context as ProcessContext } from '../../../context/process.context';
import { NavLink, Link, useSearchParams } from 'react-router-dom';
import Dropdown from 'react-bootstrap/Dropdown';
import MainProcessEnergyUseComponent from '../../../common/layout/components/main.process.energy.use.component';
import MainInstantaneousPowerComponent from '../../../common/layout/components/main.instantaneous.power.component';
import MainTotalEnergyUseComponent from '../../../common/layout/components/main.total.energy.use.component';
import MainProcessInstantaneousPowerComponent from '../../../common/layout/components/main.process.instantaneous.power.component';
import ReactGA from 'react-ga4';
import KpiMachineLayoutComponent from '../../../common/layout/components/kpi.machine.layout.component';
import ChangePageViewComponent from '../../../common/layout/components/change.page.view.component';
import MachineStatusDetailContainer from '../../machinestatus/containers/machine.status.detail.container';
import { useDispatch, useSelector } from 'react-redux';
import useHttpApiCall from '../../../services/useHttpApiCall';
import { machineStatusEnabled } from '../../../redux/company/company.action';
import { getLayerId, getLayerType } from '../../../common/functions/commonFunctions';

function EquipmentLayerDetailComponent() {
  const energyRef = useRef();
  const processRef = useRef();
  const peakRef = useRef();
  const instantRef = useRef();
  let [searchParams] = useSearchParams();
  const process_type = searchParams.get('process_type');
  const process_id = searchParams.get('process_id');
  const plant_id = searchParams.get('plant_id');
  const plant_code = searchParams.get('plant_code');
 const  equipment_id = searchParams.get('equipment_id');
 const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')=='true'?true:false

 const [payload, setPayload] = useState(null);
 const [data] = useHttpApiCall(payload);
 const dispatch = useDispatch();
//  const navigate = useNavigate();
//  const handleBackButtonClick = (event) => {
//   event.preventDefault();  // Prevent the default behavior of the Link
//   navigate(-1);  // Navigate back to the previous page
// };

 useEffect(()=>{
  if(flexibleEquipmentEnabled){
    setPayload({
      auth: true,
      url: 'v0/layers/parent-companies/basic',
      params:{
        layer_type:'plant',
        plant_code:plant_code
      }
    });
  }
},[plant_code])

useEffect(()=>{
  if(flexibleEquipmentEnabled){
    if (data?.data?.data?.company_info?. kpi_view == 'true') {
      pageViewObject = {
        energy: false,
        kpi: true
      };
    } else if (data?.data?.data?.company_info?. is_machine_status_enabled == true) {
    dispatch(machineStatusEnabled(data?.data?.data?.company_info?. is_machine_status_enabled));
    // dispatch(machineStatusEnabled(false));


      pageViewObject = {
        energy: false,
        machine_status: true,
        kpi: false
      };
    } else {
      pageViewObject = {
        energy: true,
        kpi: false,
        // machine_status: true,
      };
    }
    // setPageView(pageViewObject)
  }
 
},[data])

let prevProcess= useContext(ProcessContext).state.sidebarLinks?.layers?.filter((plants)=>
  plants.layer_id==plant_id
)[0].layers?.filter((process)=>process.layer_id==process_id)[0]
console.log('layers',prevProcess)
  let processList =prevProcess?.layers
  let chartWindows;
  console.log('processList',processList)
  processList = processList?.map((process) => ({
    process: process.name,
    process_id: process.layer_id,
    process_type: process.layer_type,
    sub_process_id: process.sub_process_id,
    is_zone_enabled: process.ugn_zones ? true : false,
    next_drill_down_is_final:process.next_drill_down_is_final
  }));

  const isMachineStatusEnabled = useSelector((state) => state.company.is_machine_status_enabled);
  // const flexibleEquipmentEnabled=localStorage.getItem('flexible_equipment')


  const sub_process_id = searchParams.get('sub_process_id');
  // const is_zone_enabled = searchParams.get('is_zone_enabled');
  const kpi_view = searchParams.get('kpi');
  const machine_status_view = searchParams.get('machine_status');
  const finel_next=searchParams.get('finel_next')=='true'?true:false;

  //const energy_view = searchParams.get('energy');
  let pageViewObject;

  if (kpi_view == 'true') {
    pageViewObject = {
      energy: false,
      kpi: true
    };
  } else if (machine_status_view == 'true') {
    pageViewObject = {
      energy: false,
      machine_status: true,
      kpi: false
    };
  } else {
    pageViewObject = {
      energy: true,
      kpi: false
    };
  }

  const [pageView, setPageView] = useState(pageViewObject);

  const { process } = useParams();

  //const [currentProcessIndex,setCurrentProcessIndex]= useState(getInitialIndex(processList,searchParams.get('process_id'),searchParams.get('sub_process_id'),searchParams.get('process_type')))
  chartWindows = finel_next?[
    {
      ref: energyRef,
      component: () => {
        return [
          MainTotalEnergyUseComponent,
          {
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            layer_id:process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            graph_name: 'Total energy use (kWh)',
            graph_tooltip:
              'The amount of power consumed by all measured equipment within this process.'
          }
        ];
      },
      id: 'energyProcess'
    },
    {
      ref: instantRef,
      component: () => {
        return [
          MainProcessInstantaneousPowerComponent,
          {
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            graph_name: 'Equipment Instantaneous Power (kW)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'instantaneousProcess'
    }
  ]:[
    {
      ref: energyRef,
      component: () => {
        return [
          MainTotalEnergyUseComponent,
          {
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            plant_id:plant_id,
            plant_code:plant_code,
            layer_id:process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            graph_name: 'Total energy use (kWh)',
            graph_tooltip:
              'The amount of power consumed by all measured equipment within this process.'
          }
        ];
      },
      id: 'energyProcess'
    },
    {
      ref: processRef,
      component: () => {
        return [
          MainProcessEnergyUseComponent,
          {
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            graph_name: 'Process energy use (kWh)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'processProcess'
    },
    {
      ref: peakRef,
      component: () => {
        return [
          MainInstantaneousPowerComponent,
          {
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            graph_name: 'Instantaneous Power (kW)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'peakProcess'
    },
    {
      ref: instantRef,
      component: () => {
        return [
          MainProcessInstantaneousPowerComponent,
          {
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            graph_name: 'Equipment Instantaneous Power (kW)',
            plant_id:plant_id,
            plant_code:plant_code,
          }
        ];
      },
      id: 'instantaneousProcess'
    }
  ];
  let params = new URLSearchParams(document.location.search);
  let energy = params.get("energy");
  let machine = params.get("machine_status");
  let kpi = params.get("kpi");

    return (
    <>
          <div className="flex-btw mb-4">
        <h6 className="inner-breadcrumb w-25">
          <Link 
          // to="#" onClick={handleBackButtonClick}
          to={`/layer_process/${encodeURIComponent(prevProcess?.name)}/?process_type=process&plant_id=${plant_id}&plant_code=${plant_code}&process_id=${process_id}`
        }
          >
            <i className=" material-icons notranslate graphoptionicons flip-icon">forward</i>{prevProcess?.name}
          </Link>
        </h6>
        <h4 className="font-light">{decodeURIComponent(process)}</h4>
        <div className="flex-c w-25 justify-content-end">
          <div className="me-3">
            <div className="process-nav-dropdown">
            {processList?.length > 1? (
                <Dropdown className="bt-dropdown-nav ">
                <Dropdown.Toggle size="sm" variant="secondary">
                  equipments
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  {processList?.map((process, i) => {
                    return (
                      <Dropdown.Item key={`processdropdown${i}`}>
                        <NavLink
                          end
                          activeClassName={'bt-dropdown--active'}
                          className={process?.plant_code === plant_code ? 'fw-bold' : ''}
                          to={
                            process.is_zone_enabled
                              ? `/layer_equipment/${encodeURIComponent(process?.process)}?/${encodeURIComponent(process?.process)}?process_type=${process.process_type}&equipment_id=${process?.process_id}&plant_code=${plant_code}&process_id=${process_id}&plant_id=${plant_id}&finel_next=${process?.next_drill_down_is_final||false}`
                              : `/layer_equipment/${encodeURIComponent(process?.process)}?/${encodeURIComponent(process?.process)}?process_type=${process.process_type}&equipment_id=${process?.process_id}&plant_code=${plant_code}&process_id=${process_id}&plant_id=${plant_id}&finel_next=${process?.next_drill_down_is_final||false}`
                          }
                          onClick={ReactGA.event({
                            category: 'Process dropdown click',
                            action: 'Selected process from dropdown',
                            label: `Process ${encodeURIComponent(process.process)} selected`
                          })}>
                          {process.process}
                        </NavLink>
                      </Dropdown.Item>
                    );
                  })}
                </Dropdown.Menu>
              </Dropdown>
            ):('')}
            
            </div>
          </div>
          <ChangePageViewComponent pageView={pageView} setPageView={setPageView} />
        </div>
      </div>

      {/* <h4 className="process-nav">
     
      { Boolean(currentProcessIndex) && <i onClick={()=>{setCurrentProcessIndex(prev => { changeRoute(prev - 1); return prev -1  })} } className="material-icons notranslate graphoptionicons flip-icon">forward</i>}
      {
        processList[currentProcessIndex].process
      }

      { Boolean(currentProcessIndex !== processList.length - 1 ) &&<i onClick={()=>{setCurrentProcessIndex(prev => { changeRoute(prev + 1); return prev +1  })} } className=" material-icons notranslate graphoptionicons">forward</i>}
      </h4> */}
            {pageView.energy ? (
                <MainLayoutComponent chartWindows={chartWindows} process={process} />
      ) : pageView.machine_status && isMachineStatusEnabled ? (
                        <MachineStatusDetailContainer
          processDetails={{
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            plant_code:plant_code,
            layer_id:getLayerId({
              process_id:process_id,
              equipment_id:equipment_id,
            }),
            layer_type:getLayerType({
              process_id:process_id,
              equipment_id:equipment_id,
            })

          }}
        />
              ) : (
        <KpiMachineLayoutComponent
          processDetails={{
            drilldownlevel: 'EquipmentLayer',
            process_type: process_type,
            process_id: process_id,
            equipment_id:equipment_id,
            sub_process_id: sub_process_id,
            plant_id:plant_id,
            plant_code:plant_code,
          }}
        />
      )}
    </>
  );
}

export default React.memo(EquipmentLayerDetailComponent);
