import moment from 'moment';
import DatePicker from 'react-datepicker';
import { subDays } from 'date-fns';
import { setExcelDownload } from '../../../redux/exceldownload/exceldownload.action';
import { useDispatch } from 'react-redux';

// import { useEffect, useState } from 'react';
export default function SetDownloadComponent({
  onFormSubmit,
  formData,
  onSelectOption,
  setFormData,
  openDownloadScreen,
  excelDonwload,
  selectSensorClassification,
  sensorUnit,
  sensorClassificationSlug,
  currentSingleDataTypeSensorValueInfo,
  handleSingleDataTypeSensorValue
}) {
  //const data_type = formData.data_type.value;
  const dispatch = useDispatch();
  const today = new Date();

  // Set the min date to be 30 days before today
  const minDate = new Date(today);
  minDate.setDate(today.getDate() - 30);

  //   // Set the min date to be 30 days before today
  //   const minDate = new Date(today);
  //   minDate.setDate(today.getDate() - 30);
  // },[])
  const dateFilter = (date) => {
    return moment() > date;
  };

  const startTimeFilter = (time) => {
    return moment() >= time;
  };


  const endTimeFilter = (time) => {
    if (
      formData.values.value.hourly ||
      (currentSingleDataTypeSensorValueInfo &&
        currentSingleDataTypeSensorValueInfo.value == 'hourly')
    ) {
      // for hourly data same time of start time is dissabled.
      return moment() >= time && moment(formData.start_date.value) < time;
    } else {
      return moment() >= time && moment(formData.start_date.value) <= time;
    }
  };

  const startSelectedDate = () => {
    if (
      (formData.values.value.daily ||
        (currentSingleDataTypeSensorValueInfo &&
          currentSingleDataTypeSensorValueInfo.value == 'daily')) &&
      moment(formData.start_date.value).isSame(new Date(), 'day')
    ) {
      let subDate = subDays(new Date(), 1).setHours(0, 0, 0, 0);
      setFormData((prev) => ({ ...prev, start_date: { ...prev.start_date, value: subDate } }));
      return subDate;
    } else if (
      (formData.values.value.hourly ||
        (currentSingleDataTypeSensorValueInfo &&
          currentSingleDataTypeSensorValueInfo.value == 'hourly')) &&
      moment(formData.start_date.value).isSame(formData.end_date.value)
    ) {
      let endTimeSet;
      endTimeSet = moment(formData.start_date.value).add(1, 'h').toDate();
      setFormData((prev) => ({ ...prev, end_date: { ...prev.end_date, value: endTimeSet } }));

      return formData.start_date.value;
    } else {
      return formData.start_date.value;
    }
  };

  const endSelectedDate = () => {
    if (moment(formData.start_date.value) > moment(formData.end_date.value)) {
      let endTimeSet;
      if (
        formData.values.value.hourly ||
        (currentSingleDataTypeSensorValueInfo &&
          currentSingleDataTypeSensorValueInfo.value == 'hourly')
      ) {
        // for hourly data same time gives blank sheet.
        endTimeSet = moment(formData.start_date.value).add(1, 'h').toDate();
      } else {
        endTimeSet = formData.start_date.value;
      }
      setFormData((prev) => ({ ...prev, end_date: { ...prev.end_date, value: endTimeSet } }));
      return endTimeSet;
    } else {
      if (
        (formData.values.value.daily ||
          (currentSingleDataTypeSensorValueInfo &&
            currentSingleDataTypeSensorValueInfo.value == 'daily')) &&
        moment(formData.end_date.value).isSame(new Date(), 'day')
      ) {
        let subDate = subDays(new Date(), 1).setHours(0, 0, 0, 0);
        setFormData((prev) => ({ ...prev, end_date: { ...prev.end_date, value: subDate } }));

        return subDate;
      } else {
        return formData.end_date.value;
      }
    }
  };

  const startChangeDate = (date) => {
    setFormData((prev) => ({ ...prev, start_date: { ...prev.start_date, value: date } }));
    changeStatusFalse();
  };
  const endChangeDate = (date) => {
    setFormData((prev) => ({ ...prev, end_date: { ...prev.end_date, value: date } }));
    changeStatusFalse();
  };

  const onBackToSelectEquipments = () => {
    openDownloadScreen('select_source');
    changeStatusFalse();
  };

  const changeStatusFalse = () => {
    dispatch(
      setExcelDownload({
        ...excelDonwload,
        download_status: false
      })
    );
  };

  const handleTimeInterval = () => {
    if (currentSingleDataTypeSensorValueInfo && sensorUnit != 'energy') {
      return currentSingleDataTypeSensorValueInfo.timeIntervals;
    } else {
      return formData.values.value.hourly ? 60 : 2;
    }
  };

  const handleMinDate = () => {
    // console.log('currentSingleDataTypeSensorValueInfo............',formData?.data_type?.value?.kW,sensorUnit)
    if (currentSingleDataTypeSensorValueInfo && sensorUnit != 'energy') {
      return subDays(new Date(), currentSingleDataTypeSensorValueInfo.maxSelectionDays);
    }
    else if(formData?.data_type?.value?.kW){
      return subDays(new Date(),30);

    }

  };

  return (
    <div className="set-download">
      <i
        className=" material-icons notranslate graphoptionicons flip-icon top-left-btn"
        onClick={() => onBackToSelectEquipments()}>
        forward
      </i>

      <h3>Export settings</h3>
      <p className="mt-2 mb-4 txt-15">
        {selectSensorClassification && sensorUnit != 'energy'
          ? 'Download your sensor data in an excel format.'
          : 'Download your sensor data in an excel format. Select kW or KWh, if the data should be daily, hourly or 2-minute values and dates you want to fetch.'}
      </p>
      <form onSubmit={onFormSubmit}>
        <div className="form-group">
          <label className="mb-2 mt-2">Data type</label>
          {selectSensorClassification && sensorUnit != 'energy' ? (
            <div id="data_type" className="flex-c flex-wrap">
              <button
                type="button"
                id={sensorClassificationSlug[sensorUnit].name}
                className="graph-filter-active  graph-filter-btn  mb-2">
                {sensorClassificationSlug[sensorUnit].name}
              </button>
            </div>
          ) : (
            <div id="data_type" className="flex-c flex-wrap">
              <button
                type="button"
                id="kW"
                onClick={(e) => onSelectOption(e)}
                className={
                  formData.data_type.value.kW
                    ? 'graph-filter-active  graph-filter-btn  mb-2'
                    : 'graph-filter-btn  mb-2'
                }>
                kW
              </button>
              <button
                type="button"
                id="kWh"
                onClick={(e) => onSelectOption(e)}
                className={
                  formData.data_type.value.kWh
                    ? 'graph-filter-active  graph-filter-btn  mb-2'
                    : 'graph-filter-btn  mb-2'
                }>
                kWh
              </button>
            </div>
          )}

          <label className="mb-2 mt-4">Values</label>
          {}
          {sensorUnit != 'energy' && currentSingleDataTypeSensorValueInfo ? (
            <div id="values" className="flex-c flex-wrap ">
              {sensorClassificationSlug[sensorUnit].values.map((eachValue) => {
                return (
                  <button
                    key={eachValue.value}
                    type="button"
                    id={eachValue.value}
                    onClick={(e) => handleSingleDataTypeSensorValue(e)}
                    className={` ${
                      currentSingleDataTypeSensorValueInfo.value == eachValue.value &&
                      'graph-filter-active'
                    } graph-filter-btn mb-2`}>
                    {eachValue.name}
                  </button>
                );
              })}
            </div>
          ) : (
            <div id="values" className="flex-c flex-wrap ">
              <button
                type="button"
                id="daily"
                disabled={formData.data_type.value.kW ? true : false}
                onClick={(e) => onSelectOption(e)}
                className={
                  formData.values.value.daily
                    ? 'graph-filter-active  graph-filter-btn  mb-2'
                    : 'graph-filter-btn  mb-2'
                }>
                Daily
              </button>
              <button
                type="button"
                id="hourly"
                disabled={formData.data_type.value.kW ? true : false}
                onClick={(e) => onSelectOption(e)}
                className={
                  formData.values.value.hourly
                    ? 'graph-filter-active  graph-filter-btn  mb-2'
                    : 'graph-filter-btn  mb-2'
                }>
                Hourly
              </button>
              <button
                type="button"
                id="least_interval"
                disabled={formData.data_type.value.kWh ? true : false}
                onClick={(e) => onSelectOption(e)}
                className={
                  formData.values.value.least_interval
                    ? 'graph-filter-active  graph-filter-btn  mb-2'
                    : 'graph-filter-btn  mb-2'
                }>
                2-min
              </button>
            </div>
          )}
          <label className="mb-2 mt-4">
            Start date{' '}
            <span className="subheading-grey">
              {formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? ''
                : '(with time)'}
            </span>{' '}
          </label>
          {console.log(
            'dd',
            currentSingleDataTypeSensorValueInfo && currentSingleDataTypeSensorValueInfo.hideTime
          )}
          <DatePicker
            selected={startSelectedDate()}
            onChange={startChangeDate}
            showTimeSelect={
              formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? false
                : true
            }
            dateFormat={
              formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? 'yyyy-MM-dd'
                : 'yyyy-MM-dd, HH:mm'
            }
            timeIntervals={handleTimeInterval()}
            timeFormat="HH:mm"
            className="mb-2 shadow w-100"
            excludeDates={
              formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? [new Date()]
                : []
            }
            filterDate={dateFilter}
            filterTime={startTimeFilter}
            minDate={handleMinDate()}
            calendarStartDay={1}
          />
          <label className="mb-2 mt-4">
            End date{' '}
            <span className="subheading-grey">
              {formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? ''
                : '(with time)'}
            </span>
          </label>
          <DatePicker
            selected={endSelectedDate()}
            onChange={endChangeDate}
            showTimeSelect={
              formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? false
                : true
            }
            dateFormat={
              formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? 'yyyy-MM-dd'
                : 'yyyy-MM-dd, HH:mm'
            }
            timeIntervals={handleTimeInterval()}
            timeFormat="HH:mm"
            minDate={formData.start_date.value}
            filterDate={dateFilter}
            excludeDates={
              formData.values.value.daily ||
              (currentSingleDataTypeSensorValueInfo &&
                currentSingleDataTypeSensorValueInfo.hideTime)
                ? [new Date()]
                : []
            }
            filterTime={endTimeFilter}
            calendarStartDay={1}
            className="shadow w-100"
          />
        </div>
        <button
          className="btn mt-5 btn-grad pr-5 pl-5 mx-auto"
          disabled={excelDonwload && excelDonwload.download_status ? true : false}
          type="submit">
          {excelDonwload && excelDonwload.download_status ? 'Exporting...' : 'Export'}
        </button>
      </form>
    </div>
  );
}
