import moment from 'moment';
import Highcharts from 'highcharts';

import {
  chartChartConfig,
  chartCommonConfig,
  chartTitleConfig,
  chartLineExportingConfig,
  chartLineColorsConfig,
  chartLineXaxisConfig,
  chartYaxisConfig,
  // chartLineTooltipConfig
} from '../../../config/chartCommon';
import { roundToTwoDecimal } from '../../../utilities/common.computations';

function processInstantaneousPowerChartConfig(
  chartApiData,
  graphFilter,
  graphName,
  userAccessibility,
  graphTooltip,
  setProcessedXAxis,
  setMinMaxValue,
) {
  const is_operator = localStorage.getItem('is_operator') == 'true' ? true : false;
  function checkForDuplicates(arr) {
    let duplicates = arr.filter((color, index) => arr.indexOf(color) !== index);
    return duplicates.length > 0 ? duplicates : 'No duplicates found';
}

// console.log(checkForDuplicates(colors));
  console.log('colorCode',checkForDuplicates(Highcharts.getOptions().colors))
  const config = {
    ...chartTitleConfig({
      graphTitle: graphName,
      userAccessibility: userAccessibility,
      graphTooltip: graphTooltip
    }),
    ...chartLineColorsConfig,
    ...chartCommonConfig,
    ...chartLineExportingConfig,
    chart: {
      ...chartChartConfig({ userAccessibility: userAccessibility }),
      spacingBottom: 5
    },

    ...chartLineXaxisConfig({
      graphFilter: graphFilter,
      userAccessibility: userAccessibility,
      setMinMaxValue: setMinMaxValue
      // minMaxValue: minMaxValue,
      // setProcessedXAxis: setProcessedXAxis
    }),
    ...chartYaxisConfig({ userAccessibility: userAccessibility }),
    tooltip: {
      // xDateFormat: '%a, %b %d, %H:%M',
      // valueDecimals: 2,
       formatter: function () {
        let date=` <div>${graphFilter?.filterMain==='1year'?moment(this.point.x).format('ddd, DD MMM YYYY, HH:mm'):moment(this.point.x).format('ddd, DD MMM, HH:mm')}<div>`
        let name=`     <span style="color:${this.color}">\u25CF </span>
        <div>${ this.series.name }:</div> 
        <span class="semi-bold">${roundToTwoDecimal(this.y)} ${chartApiData.unit_name ?chartApiData.unit_name :this.series.userOptions.unit_name?this.series.userOptions.unit_name: ' kW'
        }</span><br/>`
        return(`<div>
          <div>${date}</div></br><div>${name}</div>
          </div>`
        )
      }
    },
    legend: {
      enabled:is_operator,
      align: 'right',
      verticalAlign: 'bottom',
      layout: 'vertical',
      symbolHeight: 10, // Smaller height for legend symbols
      symbolWidth: 10,  // Smaller width for legend symbols
      itemStyle: {
          fontSize: '10px' // Smaller font size for legend text
      }
  },
    plotOptions: {
      series: {
        lineWidth: 2,
        type: 'line',
        marker: {
          enabled: false
        },
        dataGrouping: {
          enabled: false
        }
      }
    },
    series: chartApiData.length == 0 ? [] : chartApiData && chartApiData.data_points
  };
  return config;
}

export default processInstantaneousPowerChartConfig;
